import { Environment } from "@/config/config";
import { PaginatedResponse } from "@/models/paginated-response.type";
import { AxiosResponse } from "axios";

export class Pagination<T> {

    fn: Function;
    bookmarkStack: Array<string> = [];
    nextBookmark: string = '';
    pageSize: number;

    constructor($fn: Function, $pageSize = Environment.APP_PROPERTIES.PAGE_SIZE) {
        this.fn = $fn;
        this.pageSize = $pageSize;
        this.reset();
    }

    reset() {
        this.bookmarkStack = [];
        this.nextBookmark = '';
    }

    async getNextPage(...args: any[]): Promise<Array<T>> {
        if (!this.hasNextPage()) {
            return [];
        }

        const response: AxiosResponse = await this.fn(...args, this.pageSize, this.nextBookmark);
        const data: PaginatedResponse = response.data as PaginatedResponse;

        const count = data.metadata.fetchedRecordsCount;
        
        if (!count || count < 1) {
            this.nextBookmark = this.bookmarkStack.at(-1) || '';
            return [];
        }

        this.bookmarkStack.push(this.nextBookmark);
        this.nextBookmark = data.metadata.bookmark;
        
        return data.data as Array<T>;
    }

    async getPreviousPage(...args: any[]): Promise<Array<T>> {

        if (!this.hasPrevPage())
            return [];
        
        const bookmark = this.bookmarkStack.at(-2);
        
        this.bookmarkStack.pop();

        const response: AxiosResponse = await this.fn(...args, this.pageSize, bookmark);
        const data: PaginatedResponse = response.data as PaginatedResponse;

        const count = data.metadata.fetchedRecordsCount;
        if (count && count > 0) {
            this.nextBookmark = data.metadata.bookmark;
        }

        return data.data as Array<T>;
    }

    hasNextPage(): boolean {
        return this.nextBookmark !== this.bookmarkStack.at(-1);
    }

    hasPrevPage(): boolean {
        const bookmark = this.bookmarkStack.at(-2);

        return bookmark !== undefined;
    }

}