
import GlobalEvent from "@/common/global-event";
import HeaderContent from "@/components/template/HeaderContent.vue";
import { Animal } from "@/models/animal/animal.model";
import router from "@/router";
import animalService from "@/services/animal.service";
import producerService from "@/services/producer.service";
import { defineComponent, h } from "@vue/runtime-core";
import { Pagination } from "@/common/pagination";
import { NButton, NDataTable, NSelect, NSpace } from "naive-ui";
import { dateFilter } from "@/filters/custom-filters";
import { Environment } from "@/config/config";
import store from "@/store";

const createColumns = () => {
  return [
    {
      title: "Id Animal Fazenda",
      key: "idAnimalFazenda",
      sorter: "default",
    },
    {
      title: "Gênero",
      key: "sexo",
      sorter: "default",
    },
    {
      title: "Data de Nascimento",
      key: "dataNascimento",
      sorter: "default",
      render(row: Animal) {
        return dateFilter(row.dataNascimento?.toString());
      },
    },
    {
      title: "ID Blockchain",
      key: "idBlockchain",
      sorter: "default",
    },
    {
      title: "Ações",
      key: "acoes",
      render(item: Animal) {
        return [
          h(
            NButton,
            {
              size: "small",
              onClick: () =>
                router.push({
                  name: "animalDetail",
                  params: {
                    animal: JSON.stringify(item),
                    idAnimal: item.idBlockchain,
                    titlePage: `Detalhes do Animal`,
                  },
                }),
              text: true,
              class: "me-2",
              title: "Visualizar animal",
            },
            h("i", {
              class: "bi bi-search p-1 safe-icon-yellow",
            })
          ),
          h(
            NButton,
            {
              size: "small",
              onClick: () =>
                router.push({
                  name: "historyAnimal",
                  params: { idAnimal: item.idBlockchain },
                }),
              text: true,
              class: "me-2",
              title: "Visualizar histórico do animal",
            },
            h("i", {
              class: "bi bi-book p-1 safe-icon-yellow",
            })
          ),
          h(
            NButton,
            {
              size: "small",
              onClick: () => {
                GlobalEvent.emit("dialog-batch", item.idLoteSync);
              },
              text: true,
              title: "Visualizar as informações do lote",
            },
            h("i", {
              class: "bi bi-box-seam p-1 safe-icon-yellow",
            })
          ),
        ];
      },
    },
  ];
};

const queryTypes = [
  { label: "-", value: "" },
  { label: "Id Blockchain", value: "idblockchain" },
  { label: "Id Animal Fazenda", value: "idanimalfazenda" },
  { label: "Id Brinco", value: "brinco" },
  { label: "Id Sisbov", value: "sisbov" },
  { label: "Id ICar", value: "icar" },
];

const SearchAnimal = defineComponent({
  name: 'SearchAnimal',
  components: {
    HeaderContent,
    NSelect,
    NSpace,
    NDataTable,
    NButton,
  },

  data() {
    return {
      QUERY_TYPES: queryTypes,
      form: { currentProducerID: "", queryType: "", query: "" },
      listAnimals: Array<Animal>(),
      listProducers: [],
      columns: createColumns(),
      paginator: new Pagination<Animal>(
        animalService.getAnimalsByIdProducer,
        Environment.APP_PROPERTIES.PAGE_SIZE
      ),
    };
  },

  methods: {
    dateFilter,

    getProducers() {
      GlobalEvent.emit("appLoading", true);
      producerService
        .getAll()
        .then((response) => {
          this.listProducers = response.data.map((e: any) => {
            return {
              label: e.data.nome,
              value: e.data.idBlockchain,
            };
          });
        })
        .finally(() => GlobalEvent.emit("appLoading", false));
    },

    clearFilters() {
      this.form.currentProducerID = "";
      this.form.query = "";
      this.form.queryType = "";
      this.listAnimals = [];
    },

    searchAnimals() {
      if (this.form.currentProducerID == "") {
        GlobalEvent.emit("dialog", {
          icon: "bi bi-exclamation-circle",
          messageLabel: "Selecionar um produtor para filtrar",
          btnSuccessLabel: "Entendi",
          btnSuccessFunction: () => {
            GlobalEvent.emit("dialog", "hide");
          },
        });

        return;
      }

      if (this.form.queryType !== "" && this.form.query.length < 1) {
        GlobalEvent.emit("dialog", {
          icon: "bi bi-exclamation-circle",
          messageLabel: "É necessário preencher o filtro",
          btnSuccessLabel: "Entendi",
          btnSuccessFunction: () => {
            GlobalEvent.emit("dialog", "hide");
          },
        });

        return;
      }

      GlobalEvent.emit("appLoading", true);

      this.listAnimals = [];
      this.paginator.reset();
      this.paginator
        .getNextPage(
          this.form.currentProducerID,
          this.form.queryType,
          this.form.query
        )
        .then(this.updateAnimals);
    },

    updateAnimals(animals: Array<Animal>) {
      GlobalEvent.emit("appLoading", false);

      this.listAnimals = animals;
    },

    onPrevPageClick: function () {
      const paginator = this.paginator as Pagination<Animal>;
      GlobalEvent.emit("appLoading", true);
      paginator
        .getPreviousPage(
          this.form.currentProducerID,
          this.form.queryType,
          this.form.query
        )
        .then((animals) => {
          if (animals.length < 1) {
            GlobalEvent.emit("appLoading", false);
            return;
          }

          this.updateAnimals(animals);
        });
    },

    onNextPageClick() {
      const paginator = this.paginator as Pagination<Animal>;
      GlobalEvent.emit("appLoading", true);
      paginator
        .getNextPage(
          this.form.currentProducerID,
          this.form.queryType,
          this.form.query
        )
        .then((animals) => {
          if (animals.length < 1) {
            GlobalEvent.emit("appLoading", false);
            return;
          }

          this.updateAnimals(animals);
        });
    },

    navigateTo(args: any) {
      store.dispatch("setLastState", this.$data);
    },
  },

  beforeMount() {
    this.getProducers();
  },
});

export default SearchAnimal;
